/*global $*/
/*eslint no-undef: "error"*/

export default class CartButton {
    classButton = 'cart-small--button'
    classButtonOpen = 'cart-small-open'

    classBlock = 'cart--block'
    classBlockOpen = 'header-right__cart-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
