/*global $*/
/*eslint no-undef: "error"*/

export default class ProductCount {
    classItem = 'button-count'
    classCount = 'button-count__input'
    classChange = 'button-count--change'

    classButtonMinus = 'button-count__button--minus'
    classButtonPlus = 'button-count__button--plus'

    getPrice = (offers, count) => {
        if (offers) {
            let isFind = false

            let result =  offers.split('||').reverse().filter(item => {
                let itemArr = item.split('=')

                if ((parseInt(count) >= parseInt(itemArr[0])) && !isFind) {
                    isFind = true
                    return 12
                }
            })

            return (result.length === 1)? result[0].split('=')[1]: 0
        } else {
            return 0
        }
    }

    constructor () {
        let self = this

        $(document).on('click', `.${self.classButtonPlus}`, function () {
            let objCount = $(this).closest(`.${self.classItem}`).find(`.${self.classCount}`)
            let step = parseInt(objCount.attr('step'))
            let max = parseInt(objCount.attr('max'))
            let count = parseInt(objCount.val())
            let offers = objCount.data('offers')
            let productID = objCount.data('id')
            let classPrice = objCount.data('class-price')
            let price = 0

            if ((count < max) && ((count + step) <= max)) {
                count += step

                objCount.val(count)
                $(this).closest(`.${self.classItem}`).find(`.${self.classChange}`).click()

                price = self.getPrice(offers, count)
                if (price > 0) {
                    $(`.${classPrice}-${productID}`).text(price)
                }
            }
        })

        $(document).on('click', `.${self.classButtonMinus}`, function () {
            let objCount = $(this).closest(`.${self.classItem}`).find(`.${self.classCount}`)
            let step = parseInt(objCount.attr('step'))
            let min = parseInt(objCount.attr('min'))
            let count = parseInt(objCount.val())
            let offers = objCount.data('offers')
            let productID = objCount.data('id')
            let classPrice = objCount.data('class-price')
            let price = 0

            if ((count > min) && ((count - step) >= min)) {
                count -= step

                objCount.val(count)
                $(this).closest(`.${self.classItem}`).find(`.${self.classChange}`).click()

                price = self.getPrice(offers, count)
                if (price > 0) {
                    $(`.${classPrice}-${productID}`).text(price)
                }
            }
        })
    }
}
