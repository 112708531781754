/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonCatalog {
    classButton = 'button--catalog'
    classButtonOpen = 'button-catalog-open'
    classBlock = 'menu-popup'
    classBlockOpen = 'menu-popup-open'

    constructor () {
        let self = this
        let blockObj = $(`.${self.classBlock}`)

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)
            blockObj.toggleClass(self.classBlockOpen)
        })

        $(`body`).mouseup(function (e) {
            let buttonObj = $(`.${self.classButton}`)

            if ((!buttonObj.is(e.target)) && (buttonObj.has(e.target).length === 0) && (blockObj.hasClass(self.classBlockOpen))) {
                blockObj.removeClass(self.classBlockOpen)
            }
        })
    }
}
