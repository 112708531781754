/*global $*/
/*eslint no-undef: "error"*/

export default class FilterButton {
    classButton = 'filter--button'
    classItem = 'section-filter'
    classItemOpen = 'section-filter-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
