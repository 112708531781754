/*global $*/
/*eslint no-undef: "error"*/

export default class MenuToggle {
    classButton = 'menu-toggle--button'
    classButtonOpen = 'menu-toggle-open'

    classBlock = 'header-mobile--block'
    classOpen = 'header-mobile-open'

    classBody = 'body'
    classBodyHidden = 'overflow-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`html`).animate({
                scrollTop: 0
            },
                200,
                'swing',
                () => {
                    console.log(self.classButtonOpen)
                    $(`.${self.classButton}`).toggleClass(self.classButtonOpen)
                    $(`.${self.classBlock}`).toggleClass(self.classOpen)
                    $(`${self.classBody}`).toggleClass(self.classBodyHidden)
                    $(`.header-fixed`).toggleClass(`header-fixed-none`)
                }
            )
        })
    }
}
