<template lang="pug">
.form-popup
  .form-popup__block(v-show="!send")
    .h2.center(v-html="formTitle")
    .form-popup__fields
      .form-popup__field
        input.input-text(
          type="text"
          v-model="fieldName"
          :placeholder="placeholderFieldName"
        )

      .form-popup__field
        input.input-text(
          type="tel"
          v-model="fieldPhone"
          :placeholder="placeholderFieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          :class="{'input-text__input-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
        )
      .form-popup__field
        textarea.input-textarea(
          :placeholder="placeholderFieldText"
          v-model="fieldText"
        )

    .form-popup__consent
      label.consent
        input.consent__input(
          type="checkbox"
          v-model="consent"
          value="consent"
          checked="checked"
        )
        span.consent__label(v-html="formConsent")

    .form-popup__button
      .button.button-background(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButton")

  .form-popup__block(v-show="send")
    .h3.center(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopup',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
      "formSettingTitle",
      "formSettingSubject",
      "formTitle",
      "formButton",
      "formConsent",
      "formSuccessTitle",
      "formSuccessText",
      "formFieldName",
      "formFieldPhone",
      "formFieldText",
      "formFieldPageName",
      "formFieldPageId"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      send: false,
      placeholderFieldName: this.formFieldName,
      placeholderFieldPhone: this.formFieldPhone + ': *',
      placeholderFieldText: this.formFieldText,
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPageName,
              value: this.formFieldPageId
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldText = ''

        setTimeout(() => {
          this.send = !this.send
          $('.carousel__button').click()
        }, 5000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
